.dropdown-box {
  width: 278px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 5px 40px 0 rgba(94, 92, 154, 0.06);
  position: relative;
  font-family: Montserrat;
  &.header-dropdown {
    position: absolute;
    z-index: 9999;
    top: 94px;
    right: 40px;
    opacity: 0;
    visibility: hidden;
    transform: translate(0, -40px);
    transition:
      transform 0.4s ease-in-out 0s,
      opacity 0.4s ease-in-out 0s,
      visibility 0.4s ease-in-out 0s;

    &.active {
      opacity: 1;
      visibility: visible;
      transform: translate(0px, 0px);
    }
  }
}

.dropdown-navigation {
  width: 278px;
  text-align: center;
  padding: 20px 28px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 5px 40px 0 rgba(94, 92, 154, 0.06);

  .dropdown-navigation-button {
    width: 100%;
    margin-top: 15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16.8px;
    letter-spacing: 0%;

  }
}
