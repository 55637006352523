.card-plan-menu {
  display: flex;
  align-items: center;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  margin: 10px 0;
  height: fit-content;
  padding: 10px;
  border: 2px solid #fff;
  box-shadow: 0px 0px 4px 0px #0000001F;
  cursor: pointer;
  transition: background 0.3s, border 0.3s;
  font-family: Montserrat;
  .card-plan-content-menu {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .card-plan-text-menu {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    p {
      font-weight: 500;
      font-size: 12px;
      line-height: 16.8px;
      letter-spacing: 0%;
      text-align: start;
      color: #000000DE;
      margin: 0;
    }
  }
  .flex-end{
    display: flex;
    align-items: center;
    justify-content: end;
    margin-left: auto;
    .notification {
      background-color: #D1F6F6;
      padding: 5px;
      border-radius: 4px;
      color: #0288D1;
      margin-right: 10px;
    }
  }


  .notification:empty {
    background: none;
    padding: 0;
  }

  .card-plan-icon-menu {
    color: #950099;
  }
}
