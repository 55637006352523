.theme-lugarh {
  $box-shadow-default: 0px 3px 1px -2px var(--base-color-shadow);
  $box-shadow-hover: 0px 4px 5px 0px var(--base-color-shadow);
  .theme-button {
    width: fit-content;
    // font-family: Quicksand;
    min-width: fit-content;
    height: 48px;
    gap: 8px;
    text-transform:capitalize;

    &__center-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
    }
    .MuiButton-icon {
      margin-right: initial;
    }

    svg {
      width: 24px;
    }

    &--primary, &--primary-blue {
      background-color: var(--tertiary-color);
      box-shadow: $box-shadow-default;
      color: var(--light-color);

      &:hover {
        background-color: var(--tertiary-color-dark);
        box-shadow: $box-shadow-hover;
      }

      &:active {
        background-color: var(--tertiary-color);
        box-shadow: $box-shadow-hover;
      }

      &:disabled {
        background-color: var(--lighter-color-grey);
        box-shadow: initial;
      }
    }

    &--primary-blue {
      background-color: var(--secondary-color);

      &:not(:disabled) {
        &:hover {
          background-color: var(--lighter-color-grey);
        }
      }

      &:active {
        background-color: var(--secondary-color);
      }
    }

    &--secondary {
      box-shadow: $box-shadow-default;
      background-color: var(--secondary-color);
      height: 36px;
      color: var(--light-color);

      &:hover {
        background-color: var(--secondary-color-dark);
        box-shadow: $box-shadow-hover;
      }

      &:active {
        background-color: var(--secondary-color);
        box-shadow: $box-shadow-hover;
      }

      &:disabled {
        background-color: var(--lighter-color-grey);
        box-shadow: initial;
      }
    }

    &--outlined {
      background-color: transparent;
      border: 1px solid var(--secondary-color);
      color: var(--secondary-color);

      &:hover, &:active {
        background-color: var(--secondary-color);
        color: var(--light-color);
      }

      &:disabled {
        background-color: transparent;
        border-color: var(--lighter-color-grey);
      }
    }

    &--text-only {
      background-color: none;
      border: none;
      color: var(--secondary-color);
      height: 28px;

      &:disabled {
        background-color: transparent;
      }
    }

    &:disabled {
      color: var(--medium-grey-color);
      cursor: not-allowed;
      pointer-events: auto;
    }
  }
}
