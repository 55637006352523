@import '../../assets/styles/variables';

.navigation-widget {
  width: 221px;
  background-color: $white-color;
  box-shadow: 0 0 40px 0 $shadow-sidebar;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }

  &.active {
    padding: 20px;
  }

  &.closed {
    li {
      margin-top: 30px;
    }
  }

  &.navigation-widget-mobile {
    display: none;

    @media (max-width: 680px) {
      display: block;
    }
  }

  &.navigation-widget-desktop {
    display: block;
    height: 100%;

    .workspace-card {
      font-family: Quicksand;
      height: 52px;
      border-radius: 8px;
      padding: 8px;
      border: 1px solid $border-sidebar;
      display: flex;
      align-items: center;
      gap: 4px;
      max-width: 100%;

      &__icon {
        width: 32px;
        height: 32px;
        background: $primary-color-lugarh;
        color: $white-color;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        margin-right: 5px;
        font-size: 14px;
        flex-shrink: 0;
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
      }

      &__title {
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0;
        margin: 0;
        margin-bottom: 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__subtitle {
        font-weight: 600;
        font-size: 12px;
        letter-spacing: 0;
        color: $text-subtitle;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150px;
        display: inline-block;
        position: relative;
        cursor: pointer;
      }
      &__button {
        margin-top: 10px;
        font-size: 12px;
        background: $color-button;
        border-radius: 8px;
        color: $text-button;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 10px;
        box-shadow: none;
        &__icon{
        width: 20px;
        height: 20px;
        }
      }
}
}
  .MuiAvatar-root {
    height: 45px;
    width: 45px;
  }

  ul {
    &.divider{
      width: 100%;
      height: 1px;
      background-color: $border-color;
    }
    &.small {
      li {
        &.active {
          a {
            border-radius: 30px;
            background: $bg-color-active;
            height: 45px;
            width: 45px;
            transition: none;
            & :hover{
              background: $color-active;
              & .link-icon {
                fill: $white-color;
              }
            }
          }
        }

        a {
          padding-left: 0;

          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;

          & .link-icon {
            position: static;
          }

          &:hover {
            padding-left: 0;
          }
        }
      }
    }

    li {
      padding: 0;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      &.active {
        a {
          color: $color-active;
          background-color: $bg-color-active;

          &:hover {
            background-color: $text-button;
            color:$white-color;
            box-shadow: 0px 0px 14px 0 rgba(0, 0, 0, 0.2);

            .link-icon {
              fill: $white-color;
            }
          }

          .link-icon {
            fill: $color-active;
          }
        }
      }

      span {
        display: block;
        height: 48px;
        border-radius: 30px;
        font-size: 0.875rem;
        font-weight: 700;
        line-height: 48px;
        position: relative;
        transition: all 0.2s ease-in-out;
        color: $icon-color;
      }

      a {
        display: block;
        height: 40px;
        padding-left: 45px;
        border-radius: 8px;
        font-size: 0.875rem;
        font-weight: 700;
        line-height: 42px;
        position: relative;
        transition: all 0.2s ease-in-out;
        color: var(--text-primary-color);

        &.area {
          padding-left: 0px;
        }

        &:hover {
          background-color: $white-color;
          color: $color-hover;
          padding-left: 45px;
          box-shadow: 3px 5px 20px 0 $shadow-sidebar;

          &.area {
            padding-left: 0px;
          }

          .link-icon {
            fill: $link-icon-color;
          }
        }

        .link-icon {
          position: absolute;
          top: 9px;
          left: 14px;
          height: 24px;
          width: 24px;
          pointer-events: none;
          transition: all 0.2s ease-in-out;
          fill: $link-icon-color;
        }
      }
    }
  }

  .navigation-widget-info-wrap {
    padding: 32px 0 0 30px;
    margin-bottom: 40px;

    .navigation-widget-info {
      min-height: 44px;
      padding: 8px 0 0 50px;
      position: relative;

      .user-avatar {
        position: absolute;
        top: 0;
        left: 0;
      }

      .navigation-widget-info-title {
        font-size: 0.875rem;
        font-weight: 700;
        margin: 0;

        a {
          color: $color-hover;
          font-weight: 700;
        }
      }

      .navigation-widget-info-text {
        margin-top: 4px;
        color: $color-info-link;
        font-size: 0.75rem;
        font-weight: 500;
      }
    }

    .navigation-widget-info-button {
      margin-top: 20px;
      width: 140px;
    }
  }
}

.sidebar {
  height: 100%;
  position: fixed;
  top: 70px;
  z-index: 97;

  &.left {
    left: 0;
  }

  &.navigation-widget {
    transition: -webkit-transform 0.35s ease-in-out;
    transition: transform 0.35s ease-in-out;
    transition:
      transform 0.35s ease-in-out,
      -webkit-transform 0.35s ease-in-out;

    &.closed {
      overflow-y: visible;
    }

    &.navigation-widget-mobile {
      margin-top: 0;
      z-index: 100000;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: $white-color;
      }

      &::-webkit-scrollbar-thumb {
        background: $icon-color;
        border-radius: 10px;
        border-right: 2px solid $white-color;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: $scrollbar-color;
      }
    }

    &.delayed {
      transition-delay: 0.25s;
    }

    &.hidden {
      -webkit-transform: translate(-100%, 0);
      transform: translate(-100%, 0);
    }
  }
}

.overlay {
  visibility: hidden;
  background-color: $bg-color-overlay;
  transition:
    opacity 0.3s ease-in-out 0s,
    visibility 0.3s ease-in-out 0s;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99998;
  top: 0px;
  left: 0px;
  opacity: 0;
  display: none;

  @media (max-width: 680px) {
    display: block;
  }

  &.open {
    visibility: visible;
    opacity: 1;
  }
}

.navigation-widget .navigation-widget-close-button {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  fill: $color-hover;
  width: 70px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;

  svg {
    width: 14px;
    height: 10px;
  }
}

.navigation-widget .navigation-widget-section-title {
  padding-left: 30px;
  color: $icon-color;
  font-size: 0.75rem;
  font-weight: 700;
  text-transform: uppercase;
}

.navigation-widget .navigation-widget-section-title+ul {
  margin-top: 26px;
}

.navigation-widget .navigation-widget-section-title+.navigation-widget-section-link {
  margin-top: 14px;
}

.navigation-widget .navigation-widget-section-link {
  display: block;
  padding: 12px 30px;
  font-size: 0.875rem;
  font-weight: 700;
  color: $color-hover;
  transition:
    padding 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &:hover {
    color: $hover-icon;
  }
}

.navigation-widget .navigation-widget-section-link .highlighted {
  float: right;
  color: $hover-icon;
}

.navigation-widget .navigation-widget-section-link:hover {
  padding-left: 34px;
}

.navigation-widget .navigation-widget-section-link+.navigation-widget-section-title {
  margin-top: 46px;
}

.navigation-widget ul+.navigation-widget-section-title {
  margin-top: 40px;
}

.navigation-widget.closed {
  width: 68px;
  padding-top: 0px;
}

.navigation-widget.closed .user-avatar {
  margin: 0 auto;
}

  .menu-item-link {
    font-family: Quicksand;
    &__workspace {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $border-color;
      border-radius: 8px;
      padding: 6px;
    }
    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      height: 28px;
      border-radius: 4px;
      background-color: $primary-color-lugarh;
      color: $white-color;
      font-weight: 600;
      font-size: 14px;
      line-height: 17.5px;
      letter-spacing: 0%;
      text-align: center;

    }
}

.navigation-widget {
  ul.menu.small {
    li {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
