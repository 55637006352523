.theme-lugarh{
  .button.void.hiring-stage-new:hover {
    background-color: var(--secondary-color-dark);
  }
  .button.void.hiring-stage-new {
    color: var(--light-color);
    background-color: var(--medium-light-grey-color);
    width: 300px;
    height: 40px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50px;
    line-height: 15px;
    padding-top: 12px;
  }
  .error_class{
    margin: 5px;
    color: var(--error-default);
  }

}
