.dropdown-box {
  width: 346px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 5px 40px 0 rgba(94, 92, 154, 0.06);
  position: relative;
  font-family: Montserrat;
  &.header-dropdown {
    position: absolute;
    z-index: 9999;
    top: 94px;
    right: 40px;
    opacity: 0;
    visibility: hidden;
    transform: translate(0, -40px);
    transition:
      transform 0.4s ease-in-out 0s,
      opacity 0.4s ease-in-out 0s,
      visibility 0.4s ease-in-out 0s;

    &.active {
      opacity: 1;
      visibility: visible;
      transform: translate(0px, 0px);
    }
  }
}

.dropdown-navigation {
  width: 346px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 3px 5px 40px 0 rgba(94, 92, 154, 0.06);
  .user-status-text{
    color: #00000099;
    margin: 5px;
  }
  .user-status-title{
    color: #320033;
    margin: 15px;
  }
  .dropdown-navigation-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .dropdown-navigation-header a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
  }

  .dropdown-navigation-header .MuiAvatar-root {
    width: 60px;
    height: 60px;
    font-size: 24px;
  }

  .dropdown-container-button {
    font-size: 0.75rem;
    width: 100%;
    margin-top: 10px;
  }

  .dropdown-navigation-button {
    width: 100%;
    margin-top: 0px;
    font-size: 12px;
  }
}
