.theme-bne{
    .bne-footer{
        &__container{
            width: 100%;
            height: 49px;
            color: white;
            padding: 10px;
            text-align: center;
            align-items: center;
            background-color: var(--green-color-dark);
        }
    }
}