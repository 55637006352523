.theme-lugarh {
  .MuiFormControl-root {
    margin: 0;
    // max-width: 525px;
  }

  // .edit-resume .MuiInputLabel-root,
  // label.MuiInputLabel-root.Mui-focused {
  //   color: #adafca;
  //   font-size: 0.85rem;
  //   font-weight: 600;
  //   line-height: 1.2rem;
  //   font-family: 'Quicksand', sans-serif;
  // }

  // .edit-resume .MuiInputLabel-root.MuiInputLabel-shrink {
  //   transform: translate(14px, -9px) scale(1);
  // }

  .MuiOutlinedInput-root {
    height: 48px;
    border-radius: 5px;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #dedeea;

    legend {
      height: 14px;
      font-size: 0.9em;
    }
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid var(--secondary-color-light) ;
  }

  .MuiOutlinedInput-root:hover fieldset.MuiOutlinedInput-notchedOutline {
    border-color: var(--secondary-color-light);
  }
  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
    color: var(--secondary-color-light);
  }
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: var(--secondary-color-light);
  }
}
