.notification {
  &__container {
    position: fixed;
    z-index: 100000000;
    right: 30px;
    top: 90px;
    background-color: #320033;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 8px 10px 0px #00000033;
    animation: fade 5s linear forwards;

    @media (max-width: 500px) {
      right: 10px;
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    padding: 12px 16px;
  }

  &__icon-bg-container {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 1;
    width: 120px;
    height: 120px;
  }

  &__icon-bg {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-42%, -50%);
    z-index: 1;
    width: 240px;
    height: 240px;

    &--error {
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(240, 66, 72, 0.13) 0%,
        rgba(240, 66, 72, 0) 100%
      );
    }

    &--success {
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(0, 237, 81, 0.12) 0%,
        rgba(0, 237, 123, 0) 100%
      );
    }
  }

  &__message {
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.4;
    margin-left: 16px;
    gap: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &__link {
    color: #6AC425;
    text-decoration: underline;
    font-weight: 500;
    font-size: 13px;
    z-index: 10;
  }

  &__observation {
    color: #C8C5C5;
    font-weight: 500;
    font-size: 12px;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
    transform: translateY(0px);
  }
  99% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 0;
  }
}
