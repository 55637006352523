.list.section-header-info {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 60px;
  padding: 0 28px;
  border-radius: 0;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  background-color: #e3eef9;

  position: absolute;
  top: 80px;
  left: 0px;
  right: 0;

  .section-title {
    font-size: 18px;
    font-weight: 700;
    color: var(--secondary-color-light);
    display: flex;
    align-items: center;
    gap: 10px;
  }

  @media screen and (max-width: 960px) {
    -ms-flex-direction: column;
    flex-direction: column;
    height: auto;
    padding: 32px 28px;
  }
}
