$primary-color: #3084d6;
$secondary-color: #235cb2;
$tertiary-color: #6fa4f8;
$background-color: #f9f9f9;
$white-color: #ffffff;

$facebook-color: #3c88ef;
$twitter-color: #1abcff;
$linkedin-color: #2e66c2;
$whatsapp-color: #00e194;
$email-button-color: #3e99ee;

$yellow-dark-color: #a0811f;
$yellow-light-color: #fff1c9;

$title-color: #2e325e;
$grey-text: #676b8b;
$text-color: #454545;
$dark-color: #031733;

$line-color: #dadade;
$border-color: #ececec;


$primary-color-lugarh: #950099;
$notification-color: #320033;
$avatar-green-color: #d8fe04;
$shadow-color: #9500991A;
$shadow-sidebar: #5e5c9a0f;
$scrollbar-color: #525252;
$color-expand: #9898A8;
$color-hover: #3e3f5e;
$icon-color: #adafca;
$border-sidebar: #E1E1E6;
$text-subtitle: #65656E;
$link-icon-color: #757575;
$hover-icon: #00c7d9;
$color-info-link: #8f91ac;
$bg-color-overlay: #15151ff5;
$text-button: #010042;
$color-button: #E4FCFC;
$bg-color-active: #E5EEF8;
$color-active: #1A4C9B;

$font-size-small: 14px;
$font-size: 16px;
$font-size-med: 18px;
$font-size-lg: 20px;

$heading-1: 2.488rem;
$heading-2: 2.074rem;
$heading-3: 1.728rem;
$heading-4: 1.44rem;
$heading-5: 1.2rem;
$heading-6: 1rem;

$padding-global-left: 55px;
$padding-global-right: 45px;
$font-family: sans-serif;
