.theme-bne{
    .header-bne{
        &__container{
            width: 100%;
            height: 64px;
            background-color: var(--primary-color);
            padding: 15px;
            align-items: center;
            justify-content: space-between;
            display: flex;
            position: fixed;
            z-index: 10;
        }

        &__logo-bne{
            width: 150px;
        }

        &__links {
            display: flex;
            flex-direction: row;

        }

        &__link{    
                display: flex;
                flex-direction: column;
                align-items: center;
                text-decoration: none;
                color: #ffffff9c;
                position: relative;
                padding: 7px 30px;

                &:hover{
                    color: white;
                }
                &--active{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 7px 30px;
                    color: white;
                    border-bottom: 3px solid white;
                    box-sizing: border-box;
                }
        }   

        &__user{
            cursor: pointer;
            color: white;
            min-width: 97px;
            text-transform: none;
            height: 36px;
            border-radius: 32px;
            padding: 7px 55px 5px 13px;
            background-color: var(--primary-color-dark);
             &:hover{
                background-color: var(--primary-color-dark);
             }
        }

        &__menu{
            display: flex;
            position: fixed;
            right: 15px;
            max-width: 264px;
            height: 347px;
            width: 100%;
            box-shadow: 0 2px 4px rgba(0, 0, 0, .38);
            padding: 16px 16px 0;
            top: 58px;
            border-radius: 4px;
            flex-direction: column;
            // padding: 10px;
            background-color: white;
        }

        &__menu-content{
                display: flex;
                flex-direction: column;
                align-items: center;
                color: var(--primary-color-darkest);
                flex-grow: 1;
        }

        &__avatar{
            width: 72px;
            height: 72px;
            border-radius: 50%;
            margin-bottom: 14px;
            -o-object-fit: cover;
            object-fit: cover;
        }

        &__upgrade{
            border-radius: 4px;
            background-color: var(--lightest-color-grey);
            width: 100%;
            display: flex;
            align-items: center;
            font-size: 9px;
            padding: 10px;
            gap: 10px;
            margin-top: 24px;
            margin-bottom: 16px;
        }

        &__button-upgrade{
            padding: 4px 16px;
            height: 16px;
            border-radius: 8px;
            font-size: 8px;
            background-color: var(--alert-color-surface);
            color: var(--mid-color-grey);

            &:hover{
                background-color: var(--alert-color-surface);
            }
        }

        &__text{
            display: flex;
            margin-bottom: 8px;
        }

        &__avatar-mini{
            margin-bottom: 8px;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            -o-object-fit: cover;
            object-fit: cover;
            margin: 0;
        }

        &__footer-menu{
            display: flex;
            margin-top: 10px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &__footer-button{
            display: flex;
            width: 100%;
            padding: 16px;
            color: var(--primary-color-darkest);
            &--exit{
                border-top: 1px solid var(--primary-color-darkest);
                color: var(--primary-color-darkest);
                width: calc(100% + 114px);
                margin-top: 10px;
                border-radius: 0;
                height: 56px;
            }
        }
    }
}