.theme-lugarh {
    .app-structure {
        display: flex; 
        flex-direction: column; 
        min-height: 100vh; 
      &__container {
        flex: 1; 
      }
    }
  }
  