.search-bar {
  form {
    height: 52px;
  }
}

.search-results {
  position: absolute;
  z-index: 9999;
  top: 57px;
  left: 0px;
  opacity: 0;
  visibility: hidden;
  transform: translate(0px, -40px);
  transition:
    transform 0.4s ease-in-out 0s,
    opacity 0.4s ease-in-out 0s,
    visibility 0.4s ease-in-out 0s;
  overflow: auto;
  max-height: 80vh;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #adafca;
    border-radius: 10px;
    border-right: 2px solid #fff;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgb(82, 82, 82) fca;
  }

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translate(0px, 0px);
  }
}

.interactive-input {
  width: 45%;
  margin-top: auto;

  &.dark {
    align-items: center;

    input {
      height: 65% ;
      padding-right: 30px;
      border-radius: 20px;

      &::-webkit-input-placeholder {
        opacity: 1;
      }

      &::-moz-placeholder {
        opacity: 1;
      }

      &:-ms-input-placeholder {
        opacity: 1;
      }

      &::-ms-input-placeholder {
        opacity: 1;
      }

      &::placeholder {
        opacity: 1;
      }
    }

    .interactive-input-icon-wrap {
      height: 75%;
    }
  }
}

.interactive-input-action {
  .icon-cross-thin {
    width: 10px;
    height: 30px;
  }
}

.header .header-actions.search-bar .header-search-dropdown {
  width: 60%;
  margin-top: 5px;
  margin-left: 20%;
}

.interactive-input.dark {
  margin-bottom: 5px;
}

.header .header-actions {
  display: contents;
  align-items: center;
}
