/*---------------------------------
    56. SLIDER LINE
---------------------------------*/
.slider-line {
  height: 120px;
  padding: 0 48px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  position: relative;

  .section-pretitle {
    margin-bottom: 0px;
  }

  &.small {
    height: 100px;

    .slider-controls {
      top: 40px;
    }

    .slider-slides .user-stat {
      height: 100px;
    }
  }

  &.medium {
    height: 100px;

    .slider-controls {
      top: 40px;
    }

    .slider-slides {
      .reaction-stat {
        height: 80px;
        width: 146px;
        margin: 10px 0;
        cursor: pointer;
        border-bottom: 4px solid transparent;

        &.active {
          border-radius: 10px;
          background: rgba(#3084d6, 0.14);
        }

        .reaction-stat-title {
          color: var(--secondary-color);
          text-align: center;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 144.1%;
        }

        .reaction-stat-text {
          color: var(--secondary-color);
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 144.1%;
          text-align: center;
        }
      }
    }
  }

  .slider-controls {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    padding: 0 14px;
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 1;

    svg {
      height: 15px;
      width: 10px;
    }
  }

  .slider-slides {
    position: relative;
    z-index: 2;
    overflow: hidden;

    &.with-separator .slider-slide {
      position: relative;
    }

    .stat-block {
      height: 120px;
      padding-left: 28px;
      -ms-flex-align: center;
      align-items: center;
    }

    .user-stat {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-pack: center;
      justify-content: center;
    }

    .reaction-stat {
      -ms-flex-pack: center;
      justify-content: center;
    }
  }
}

/*---------------------------------
    80. REACTION STAT
---------------------------------*/
.reaction-stat {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;

  .reaction-stat-image {
    width: 40px;
    height: 40px;
  }

  .reaction-stat-title {
    margin-top: 0px;
    font-size: 1.375rem;
    font-weight: 700;
    margin-bottom: 0px;
  }

  .reaction-stat-text {
    margin-top: 8px;
    color: #adafca;
    font-size: 0.75rem;
    font-weight: 700;
    margin-bottom: 0px;
  }
}

.slider-control {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 20px;
  height: 20px;
  cursor: pointer;

  &.negative {
    .slider-control-icon {
      fill: #fff;
    }

    &[aria-disabled='true'],
    &.disabled {
      opacity: 0.4;

      &:hover {
        .slider-control-icon {
          fill: #fff;
          opacity: 0.4;
        }
      }
    }

    &:hover {
      .slider-control-icon {
        fill: #fff;
        opacity: 1;
      }
    }
  }

  &[aria-disabled='true'],
  &.disabled {
    opacity: 0.5;

    &:hover {
      .slider-control-icon {
        fill: #adafca;
        opacity: 0.5;
      }
    }
  }

  .slider-control-icon {
    fill: #adafca;
    pointer-events: none;
    opacity: 0.6;
    transition:
      fill 0.2s ease-in-out,
      opacity 0.2s ease-in-out;
  }

  &.left {
    .slider-control-icon {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }

  &:hover {
    .slider-control-icon {
      fill: #3e3f5e;
      opacity: 1;
    }
  }
}

.slick-slide {
  position: relative;

  &:last-child::after {
    content: '';
    width: 1px;
    height: 20px;
    background-color: #eaeaf5;
    position: absolute;
    top: 40px;
    right: 0;
  }
}
